var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-button',{attrs:{"disabled":_vm.disabled,"type":"primary"},on:{"click":function($event){_vm.visible = true}}},[_vm._v("添加")]),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"pagination":false,"rowKey":"key"}},[_c('a-table-column',{attrs:{"title":"问题类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"qualityProblemType","dictValue":text.type}})]}}])}),_c('a-table-column',{attrs:{"title":"问题描述","data-index":"description"}}),_c('a-table-column',{attrs:{"title":"整改要求","data-index":"demand"}}),_c('a-table-column',{attrs:{"title":"问题照片"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',[(typeof text.problemPicture === 'string')?_c('span',_vm._l((text.problemPicture.split(',')),function(item){return _c('a',{key:item,staticStyle:{"padding-right":"4px"},attrs:{"href":item,"target":"_blank"}},[_vm._v(_vm._s(_vm.$getFileName(item)))])}),0):_vm._e()])]}}])}),(!_vm.disabled)?_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a-space',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(text, index)}}},[_vm._v("修改")]),_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v("删除")])])]}}],null,false,3837339916)}):_vm._e()],1),_c('a-modal',{attrs:{"title":_vm.activeIndex !== -1 ? '修改问题' : '添加问题',"visible":_vm.visible,"footer":null,"width":"600px"},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"问题类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                { 
                  initialValue: _vm.activeText.type,
                  rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'type',\n                { \n                  initialValue: activeText.type,\n                  rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.qualityProblemType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"问题描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'description',
                { 
                     initialValue: _vm.activeText.description,
                  rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'description',\n                { \n                     initialValue: activeText.description,\n                  rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":"整改要求"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'demand',
                { 
                     initialValue: _vm.activeText.demand,
                  rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'demand',\n                { \n                     initialValue: activeText.demand,\n                  rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":"问题照片"}},[_c('Images',{attrs:{"list":_vm.images}})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("保存")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }