<template>
  <div>
    <a-table bordered :data-source="list" :pagination="false" rowKey="id">
      <a-table-column title="问题类型" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="qualityProblemType" :dictValue="text.type" />
        </template>
      </a-table-column>
      <a-table-column title="问题描述" data-index="description" />
      <a-table-column title="整改要求" data-index="demand" />

      <a-table-column title="整改措施" data-index="measure" />
      <a-table-column title="整改情况" align="center">
        <template slot-scope="text">
          <a-badge v-if="text.confirmStatus === 1" status="success" text="已完成" />
          <a-badge v-if="text.confirmStatus === 0" status="error" text="未完成" />
        </template>
      </a-table-column>

      <a-table-column title="完成时间" data-index="finishTime" align="center" />

      <a-table-column title="问题照片">
        <template slot-scope="text">
          <span>
            <span v-if="typeof text.problemPicture === 'string'">
              <a
                v-for="item in text.problemPicture.split(',')"
                :key="item"
                :href="item"
                target="_blank"
                style="padding-right: 4px"
              >{{$getFileName(item)}}</a>
            </span>
          </span>
        </template>
      </a-table-column>

      <a-table-column title="整改后照片">
        <template slot-scope="text">
          <span>
            <span v-if="typeof text.rectifyPicture === 'string'">
              <a
                v-for="item in text.rectifyPicture.split(',')"
                :key="item"
                :href="item"
                target="_blank"
                style="padding-right: 4px"
              >{{$getFileName(item)}}</a>
            </span>
          </span>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },

  methods: {}
};
</script>