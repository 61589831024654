<template>
  <div>
    <a-button :disabled="disabled" type="primary" @click="visible = true">添加</a-button>

    <Padding />

    <a-table bordered :data-source="list" :pagination="false" rowKey="key">
      <a-table-column title="问题类型" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="qualityProblemType" :dictValue="text.type" />
        </template>
      </a-table-column>
      <a-table-column title="问题描述" data-index="description" />
      <a-table-column title="整改要求" data-index="demand" />

      <a-table-column title="问题照片">
        <template slot-scope="text">
          <span>
            <span v-if="typeof text.problemPicture === 'string'">
              <a
                v-for="item in text.problemPicture.split(',')"
                :key="item"
                :href="item"
                target="_blank"
                style="padding-right: 4px"
              >{{$getFileName(item)}}</a>
            </span>
          </span>
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center" v-if="!disabled">
        <template slot-scope="text, record, index">
          <a-space>
            <a href="#" @click.prevent="edit(text, index)">修改</a>
            <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      :title=" activeIndex !== -1 ? '修改问题' : '添加问题'"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="600px"
    >
      <a-form :colon="false" @submit="handleSubmit" :form="form">
        <a-form-item label="问题类型">
          <a-select
            style="width: 100%"
            v-decorator="[
                  'type',
                  { 
                    initialValue: activeText.type,
                    rules: [{ required: true, message: '请选择！' }] },
                ]"
          >
            <a-select-option
              v-for="item in qualityProblemType"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="问题描述">
          <a-textarea
            v-decorator="[
                  'description',
                  { 
                       initialValue: activeText.description,
                    rules: [{ required: true, message: '请输入！' }] },
                ]"
          />
        </a-form-item>

        <a-form-item label="整改要求">
          <a-textarea
            v-decorator="[
                  'demand',
                  { 
                       initialValue: activeText.demand,
                    rules: [{ required: true, message: '请输入！' }] },
                ]"
          />
        </a-form-item>

        <a-form-item label="问题照片">
          <Images :list="images" />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button htmlType="submit" type="primary">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Images from "./images.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Images
  },

  props: {
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      activeIndex: -1,
      activeText: {},
      images: []
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    qualityProblemType() {
      return this.findDataDict("qualityProblemType");
    }
  },

  methods: {
    edit(text, index) {
      this.activeText = text;
      this.activeIndex = index;
      this.images = text.problemPicture ? text.problemPicture.split(",") : [];
      this.visible = true;
    },
    remove(index) {
      this.list.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);

          const params = {
            key: Date.now().toString() + Math.random(),
            ...values,
            problemPicture:
              this.images.length > 0 ? this.images.join(",") : undefined
          };
          if (this.activeIndex !== -1) {
            this.list.splice(this.activeIndex, 1, {
              ...this.activeText,
              ...params
            });
          } else {
            this.list.push(params);
          }

          this.cancel();
        }
      });
    },

    cancel() {
      this.visible = false;
      this.form.resetFields();
      this.images = [];
      this.activeIndex = -1;
      this.activeText = {};
    }
  }
};
</script>