<template>
  <span>
    <a-button @click="visible = true">打开</a-button>
    <a-modal title="安全生产检查评分表" :visible="visible" :footer="null" @cancel="cancel" width="800px">
      <div class="center" style="margin-bottom: 16px">
        <a-radio-group button-style="solid" v-model="activeType">
          <a-radio-button v-for="(item) in list" :key="item.id" :value="item.id">{{item.type}}</a-radio-button>
        </a-radio-group>
      </div>

      <a-table bordered :data-source="children" :pagination="false">
        <a-table-column title="类型" data-index="type" align="center" />
        <a-table-column title="内容">
          <template slot-scope="text">
            <span v-html="renderText(text.item)"></span>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </span>
</template>


<script>
import request from "@/api/request";

function fetchList(params) {
  return request({
    url: "/office-service/quality/inspect/subject/official/querySubjectList",
    params
  });
}

export default {
  data() {
    return {
      visible: false,
      list: [],
      activeType: null
    };
  },

  watch: {
    visible() {
      if (this.visible && this.list.length === 0) {
        this.getList();
      }
    }
  },

  computed: {
    children() {
      const res = this.list.find(item => item.id === this.activeType);
      return res && Array.isArray(res.list)
        ? res.list.map((item, index) => {
            return {
              ...item,
              key: item.type + index
            };
          })
        : [];
    }
  },

  methods: {
    renderText(text = "") {
      return text.replace(/\n/gi, "<br>");
    },
    cancel() {
      this.visible = false;
    },

    getList() {
      fetchList().then(res => {
        if (Array.isArray(res)) {
          this.list = Array.isArray(res) ? res : [];
          if (res.length > 0) {
            this.activeType = res[0].id;
          }
        }
      });
    }
  }
};
</script>