var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"检查类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                { 
                  initialValue: _vm.detail.type,
                  rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'type',\n                { \n                  initialValue: detail.type,\n                  rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true}},_vm._l((_vm.qualityInspectType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"检查实施单位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'unitId',
                {
                  initialValue: _vm.detail.unitId,
                   rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'unitId',\n                {\n                  initialValue: detail.unitId,\n                   rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"检查时间段"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'date',
            { 
                  initialValue: _vm.initialValueDate,
              rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'date',\n            { \n                  initialValue: initialValueDate,\n              rules: [{ required: true, message: '请选择！' }] },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true,"placeholder":[]}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"受检单位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'inspectUnitId',
                {
                  initialValue: _vm.detail.inspectUnitId,
                   rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'inspectUnitId',\n                {\n                  initialValue: detail.inspectUnitId,\n                   rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"受检项目","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['inspectProjectName', 
                { 
                  initialValue: _vm.detail.inspectProjectName,
                  rules: [{ required: true, message: '请输入' }] },]),expression:"['inspectProjectName', \n                { \n                  initialValue: detail.inspectProjectName,\n                  rules: [{ required: true, message: '请输入' }] },]"}],attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"受检部位","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'inspectPart',
                { 
                  initialValue: _vm.detail.inspectPart,
                  rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'inspectPart',\n                { \n                  initialValue: detail.inspectPart,\n                  rules: [{ required: true, message: '请输入！' }] },\n              ]"}],attrs:{"disabled":_vm.disabled}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"检查内容","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'inspectContent',
                {
                  initialValue: _vm.detail.inspectContent,
                   rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'inspectContent',\n                {\n                  initialValue: detail.inspectContent,\n                   rules: [{ required: true, message: '请输入！' }] },\n              ]"}],attrs:{"disabled":_vm.disabled}})],1)],1)],1),_c('a-divider'),_c('a-row',[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"参与检查人员","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('Employee',{attrs:{"list":_vm.memberList,"disabled":!!_vm.detail.status,"type":"work"}}),_c('div',{staticStyle:{"color":"red"}},[_vm._v("提交审批后无法修改，如需修改请终止此审批并重新发起。")])],1)],1)],1),_c('div',{staticClass:"footer center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.saveLoading,"disabled":_vm.disabled}},[_vm._v(_vm._s(_vm.detail.id && _vm.detail.status === 'rejected' ? '重新发起审批' : '发起审批'))]),_c('a-button',{attrs:{"loading":_vm.saveLoading,"disabled":_vm.disabled},on:{"click":_vm.onSave}},[_vm._v("保存")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }