<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        :tabBarStyle="{
                    margin: '0',
                  }"
        v-model="activeTabIndex"
      >
        <a-tab-pane
          v-for="(item,index) in filteredTabs"
          :key="index"
          :tab="item.name"
          :disabled="item.disabled"
        ></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px">
          <a-button type="primary" @click="download">导出</a-button>
        </a-space>
      </template>
    </Pane>

    <Plan v-if="activeTabIndex === 0" :detail="detail" />
    <Work v-if="activeTabIndex === 1" :detail="workDetail" />
    <Rectification v-if="activeTabIndex === 2" :detail="rectifyWorkDetail" />
    <Feedback v-if="activeTabIndex === 3" :detail="rectifyWorkFeedbackDetail" />
  </div>
</template>

<script>
import Plan from "./components/plan.vue";
import Work from "./components/work.vue";
import Rectification from "./components/rectification.vue";
import Feedback from "./components/feedback.vue";

import request from "@/api/request";
import download from "@/api/download";
import { saveAs } from "file-saver";

function fetchDetail(params) {
  return request({
    url: "/office-service/quality/inspect/plan/detail/" + params.id
  });
}

function fetchWorkDetail(params) {
  return request({
    url: "/office-service/quality/inspect/work/detail/" + params.id
  });
}

function fetchRectifyDetail(params) {
  return request({
    url: "/office-service/quality/inspect/rectify/workDetail/" + params.id
  });
}

function fetchRectifyFeedbackDetail(params) {
  return request({
    url: "/office-service/quality/inspect/rectify/feedbackDetail/" + params.id
  });
}

function fetchStatus(params) {
  return request({
    url: "/office-service/quality/inspect/plan/queryGridItem/" + params.id
  });
}

function downloadFile(params) {
  return download({
    url: `/office-service/fileExport/fileExport/${params.type}/${params.id}`
  });
}

export default {
  components: {
    Plan,
    Work,
    Rectification,
    Feedback
  },

  data() {
    return {
      activeTabIndex: 0,
      tabs: [
        {
          name: "安全检查策划表",
          disabled: false
        },
        {
          name: "安全检查工作表",
          disabled: true
        },
        {
          name: "安全检查整改工作单",
          disabled: true
        },
        {
          name: "安全检查整改反馈单",
          disabled: true
        }
      ],
      detail: {},
      workDetail: {},
      rectifyWorkDetail: {},
      rectifyWorkFeedbackDetail: {}
    };
  },

  computed: {
    filteredTabs() {
      return [
        {
          name: "安全检查策划表",
          disabled: false
        },
        {
          name: "安全检查工作表",
          disabled: this.detail.status !== "approved"
        },
        {
          name: "安全检查整改工作单",
          disabled: this.detail.status !== "approved"
        },
        {
          name: "安全检查整改反馈单",
          disabled: this.detail.status !== "approved"
        }
      ];
    }
  },

  mounted() {
    const { active } = this.$route.query;
    if (active) {
      this.activeTabIndex = parseInt(active);
    }

    this.getData();
  },
  methods: {
    getData() {
      const hide = this.$message.loading("加载中...", 0);

      const { id } = this.$route.query;

      this.detail = {};
      this.workDetail = {};
      this.rectifyWorkDetail = {};

      // 请求策划表
      fetchDetail({
        id
      })
        .then(res => {
          if (res) {
            this.detail = res;
            this.getStatus();
          }
        })
        .finally(() => {
          hide();
        });
    },

    getStatus() {
      fetchStatus({
        id: this.detail.id
      }).then(res => {
        if (Array.isArray(res)) {
          const work = res.find(item => item.type === "work");
          if (work && work.id) {
            fetchWorkDetail({
              id: work.id
            }).then(result => {
              this.workDetail = result || {};
            });
          } else {
            // 构造数据
            this.workDetail = {
              ...this.detail,
              parentId: this.detail.id,
              id: undefined,
              status: undefined,
              progress: undefined,
              name: undefined,

              memberList: Array.isArray(this.detail.memberList)
                ? this.detail.memberList.map(item => {
                    return {
                      userId: item.userId,
                      userName: item.userName,
                      duty: item.duty,
                      type: item.type,
                      parentType: "work"
                    };
                  })
                : []
            };
          }

          const rectifyWork = res.find(item => item.type === "rectifyWork");
          if (rectifyWork && rectifyWork.id) {
            fetchRectifyDetail({
              id: rectifyWork.id
            }).then(result => {
              this.rectifyWorkDetail = result
                ? {
                    ...result,
                    problemList: Array.isArray(result.problemList)
                      ? result.problemList.map(item => {
                          return {
                            ...item,
                            key: item.id
                          };
                        })
                      : []
                  }
                : {};
            });
          } else {
            // 构造数据
            this.rectifyWorkDetail = {
              ...this.detail,
              parentId: this.detail.id,
              id: undefined,
              status: undefined,
              progress: undefined,
              name: undefined,
              memberList: [],
              problemList: []
            };
          }

          const rectifyFeedback = res.find(
            item => item.type === "rectifyFeedback"
          );
          if (rectifyFeedback && rectifyFeedback.id) {
            fetchRectifyFeedbackDetail({
              id: rectifyFeedback.id
            }).then(result => {
              this.rectifyWorkFeedbackDetail = result
                ? {
                    ...result,
                    problemList: Array.isArray(result.problemList)
                      ? result.problemList.map(item => {
                          return {
                            ...item,
                            key: item.id
                          };
                        })
                      : []
                  }
                : {};
            });
          }
        }
      });
    },

    download() {
      let type = "";
      let id = "";
      let name = "";
      if (this.activeTabIndex === 0) {
        type = "quality_inspect_plan";
        name = "安全检查策划表";
        if (!this.detail.id) {
          this.$message.error("无法导出" + name);
          return;
        }
        id = this.detail.id;
      } else if (this.activeTabIndex === 1) {
        type = "quality_inspect_work";
        name = "安全检查工作表";
        if (!this.workDetail.id) {
          this.$message.error("无法导出" + name);
          return;
        }
        id = this.workDetail.id;
      } else if (this.activeTabIndex === 2) {
        type = "quality_inspect_rectify_work";
        name = "安全检查整改工作单";
        if (!this.rectifyWorkDetail.id) {
          this.$message.error("无法导出" + name);
          return;
        }
        id = this.rectifyWorkDetail.id;
      } else if (this.activeTabIndex === 3) {
        type = "quality_inspect_rectify_feedback";
        name = "安全检查整改反馈单";
        if (!this.rectifyWorkFeedbackDetail.id) {
          this.$message.error("无法导出" + name);
          return;
        }
        id = this.rectifyWorkFeedbackDetail.id;
      }

      const hide = this.$message.loading("导出中...", 0);

      downloadFile({
        type,
        id
      })
        .then(blob => {
          saveAs(blob, `${name}.docx`);
        })
        .finally(() => {
          hide();
        });
    }
  }
};
</script>