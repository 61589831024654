<template>
  <a-card class="container">
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" :colon="false">
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="检查实施单位">
            <a-button style="text-align:left" block>{{ detail.unitName}}</a-button>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="受检单位">
            <a-button style="text-align:left" block>{{ detail.inspectUnitName }}</a-button>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="受检项目">
            <a-button style="text-align:left" block>{{ detail.inspectProjectName }}</a-button>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="受检部位">
            <a-button style="text-align:left" block>{{ detail.inspectPart }}</a-button>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="整改依据">
            <a-button style="text-align:left" block>{{ detail.evidence }}</a-button>
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider />

      <a-row>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="问题与整改计划清单" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <ProblemsAll :list="problemList" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="项目整改负责人" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <a-button style="text-align:left">{{ user.userName }}</a-button>
          </a-form-item>
        </a-col>

                <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="项目整改人" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
            <a-button style="text-align:left">{{ rectifier.userName }}</a-button>
          </a-form-item>
        </a-col>

      </a-row>

      <div class="footer center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-form>
  </a-card>
</template>

<script>
import watermark from "@/mixins/watermark";
import ProblemsAll from "./problems-all.vue";

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },

  mixins: [watermark],

  components: {
    ProblemsAll
  },

  data() {
    return {
      form: this.$form.createForm(this),
      problemList: []
    };
  },

  computed: {
    user() {
      if (
        Array.isArray(this.detail.memberList) &&
        this.detail.memberList.length > 0
      ) {
        const obj = this.detail.memberList.find(
          item => item.type === "rectifyManager"
        );
        return obj ? obj : {};
      } else {
        return {};
      }
    },
        rectifier() {
      if (
        Array.isArray(this.detail.memberList) &&
        this.detail.memberList.length > 0
      ) {
        const obj = this.detail.memberList.find(
          item => item.type === "rectifier"
        );
        return obj ? obj : {};
      } else {
        return {};
      }
    }
  },

  watch: {
    detail: {
      handler() {
        this.initData();
      },
      deep: true
    }
  },

  mounted() {
    this.initData();
  },

  methods: {
    initData() {
      if (Array.isArray(this.detail.problemList)) {
        this.problemList = this.detail.problemList;
      }
    }
  }
};
</script>


<style lang="less" scoped>
.container {
  padding-top: 24px;
}
.footer {
  padding: 80px 0;
}
</style>